import React from 'react';
// import {Content } from './styles';

const Bar = ({ languages }) => (
    <div>
    <span style={{"backgroundColor": "black", "display": "flex", "height": "10px", "border-radius": "6px"}}>
        {languages.edges.map((lang) => {
            return  <span style={{
                "display": "block",
                "backgroundColor": lang.node.color,
                "width": Math.round(lang.size*100.0/languages.totalSize)+"%",
                "padding":"5px",
                "border-radius": "4px"
                }}/>
        })}
        </span>
    </div>
);

export default Bar;